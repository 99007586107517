import "./scss/login.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useEffect, useRef, useState } from "react";


import login from "../../assets/images/login.svg";
import { useForm } from "react-hook-form";

import { CONST } from "../../config";

import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from "react-router-dom";
import Helper from "../../common/Helper";
import ReCAPTCHA from "react-google-recaptcha";
import { cryptoModule } from "../../common/Services";
const Login = () => {
  toast.configure()

  const recaptchaRef = useRef();
  const [isVerified, setIsVerified] = React.useState(false);
  const [showEye, setshowEye] = useState(false);
  const [button, setButton] = useState(false)
  const navigate = useNavigate()
  const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: "onChange" });
  const [captchaValue, setCaptchaValue] = useState('');

  useEffect(() => {
    if (localStorage.getItem('loginId')) {
      navigate("/");
    }
  }, []);

  const onSubmit = async (data) => {
    if (!captchaValue) {
      // If reCAPTCHA value is not available, prevent form submission
      toast.error('Please complete the RECAPTCHA verification');
      return;
    }
    setButton(true)
    let data1 = {
      'email': data.email,
      'password': data.password,
      'google_captcha': captchaValue
    }

    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/dii2115iioFTUQqxjuph`, data1)
    if (getdata?.status == true) {
      sessionStorage.setItem('email', cryptoModule.encrypt(data?.email));
      sessionStorage.setItem('password', cryptoModule.encrypt(data?.password));
      if (getdata?.loginotp == 0) {
        navigate('/otp')
        toast.success(getdata?.msg)
      }
      else if (getdata?.tfa_status == 1 && getdata?.tfa_code != '') {
        navigate('/tfa')
        toast.success(getdata?.msg)
      }
      else {
        navigate('/')
        window.location.reload();
      }
    }
    else if (getdata?.status == 401) {
      toast.error(getdata?.msg)
      navigate('/ipblock')
    }
    else if (getdata?.status == false) {
      setButton(false);
      toast.error(getdata?.msg);
    }
    setButton(false);
    setCaptchaValue('');

    reset();
    recaptchaRef?.current?.reset();
    setIsVerified(false);

  }

  const AvoidSpace = (event) => {
    var k = event ? event.which : window.event.keyCode;
    if (k == 32) event.preventDefault()
  }

  const onChange = (value) => {
    setCaptchaValue(value);
    setIsVerified(true);
  };

  return (
    <Fragment>
      <Header />



      
      <div className="cntld">
        <div className="w-100 LoginRegContainer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                    <img src={login} className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6">
                    <form className="loginRegForm" onSubmit={handleSubmit(onSubmit)}>
                      <div className="heading">
                        <h5>Welcome back</h5>
                        <h3>Login to your account</h3>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="email">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          onKeyDown={(event) => AvoidSpace(event)}
                          {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                        />
                        {errors.email && errors.email.type === 'required' && <span className='errors'>Please Enter the Email</span>}
                        {errors.email && errors.email.type === 'pattern' && <span className='errors'>Please Enter Valid Email</span>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="password">Password</label>
                        <div className="eyeIcon">
                          <input
                            type={showEye ? 'text' : 'password'}
                            className="form-control"
                            placeholder="Password"
                            onKeyDown={(event) => AvoidSpace(event)}
                            {...register('password', { required: true })}
                          />
                          <button className="btn" type="button" onClick={() => setshowEye(!showEye)}>
                            {showEye ?
                              <img src={require('../../assets/images/eye.svg').default} className='img-fluid eye-show' alt="eye" />
                              :
                              <img src={require('../../assets/images/eye-slash.svg').default} className='img-fluid eye-hide' alt="eye-slash" />
                            }
                          </button>
                        </div>
                        {errors.password && errors.password.type === 'required' && <span className='errors'>Please Enter the Password</span>}
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <ReCAPTCHA name="g-recaptcha-response" sitekey={Helper.Sitekey}
                          ref={recaptchaRef}
                          value={recaptchaRef?.current?.value} onChange={onChange} />
                        <Link to='/forgot' className="ms-auto">Forgot Password ?</Link>
                      </div>
                      <div className="my-5">
                        <button className="btn btn-block orangeBtn w-100" disabled={button}> {button ? <ClipLoader color={'#ffffff'} size={25} /> : <span>Login Now</span>}</button>
                      </div>
                      <div className="mb-3">
                        <p className="text-center">Don't have an account ?<Link to="/register">Join free today</Link></p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );

}

export default Login;