
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer12/Footer";
import { useEffect, useState } from "react";
import Helper from "../../common/Helper";
import { CONST } from "../../config";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment"; // Import moment


const Terms = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        getData()
    }, []);

    const [cmsdata, setcmsdata] = useState()
    const [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffffff");
    const [termTitle, setTermtitle] = useState();
    const [termTime, setTermtime] = useState();





    const getData = async () => {
        setLoading(true);
        let getdata = await Helper.GetData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/EKu1mCix4vAxDWLki7Vq`);
        if (getdata?.status == true) {
            setcmsdata(getdata.cmsdata[0])
            setTermtitle(getdata.cmsdata[0].title)
            const formattedDate = moment(getdata.cmsdata[0].updated_at).format('MMM D, YYYY');
            setTermtime(formattedDate);



        }
        setLoading(false);
    }


    return (
        <>
            <div className={loading ? "spinner" : "d-none"}>
                <ScaleLoader color={color} size={50} />
            </div>
            <Header />
            <div className="CntLdDv">
                <div className="container">
                    <div className="PrvPlyPg">
                        <div className="PrvPlyTp">
                            <h3>{termTitle}</h3>
                            <h4>Last updated: {termTime}</h4>
                        </div>
                        <div className="PrvPlyCnt" dangerouslySetInnerHTML={{ __html: cmsdata?.content }}></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}

export default Terms;