import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import uploadImg from '../../assets/images/upload.png';
import Helper from "../../common/Helper";
import { CONST } from "../../config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const KYC = () => {
    const format = ["image/jpg", "image/png", "image/jpeg"];
    const [getKycInfo, setKycInfo] = useState("");
    const [kycFront, setKycFront] = useState("");
    const [kycBack, setKycBck] = useState("");
    const [kycSelfie, setKycSelfie] = useState("");
    const [kycFrontPreview, setKycFrontPreview] = useState("");
    const [kycBackPreview, setKycBackPreview] = useState("");
    const [kycSelfiePreview, setKycSelfiePreview] = useState("");
    const [updateRes, setUpdateRes] = useState("");
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState("#ffffff");
    const [proofType, setProofType] = useState("");
    const [getProofType, setGetProofType] = useState('');

    useEffect(() => {
        getUserDetails();
    }, [updateRes]);

    const getUserDetails = async () => {
        setLoading(true);
        let res = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/t6gep9ZEy2XFauVM8o9P`);
        if (res?.status) {
            setKycInfo(res?.user);
            res?.user?.kyc_photo_proof1_status === 1 ? setKycFront(res?.user?.kyc_photo_proof1) : setKycFrontPreview(res?.user?.kyc_photo_proof1);
            res?.user?.kyc_photo_proof2_status === 1 ? setKycBck(res?.user?.kyc_photo_proof2) : setKycBackPreview(res?.user?.kyc_photo_proof2);
            res?.user?.kyc_photo_self_status === 1 ? setKycSelfie(res?.user?.kyc_photo_self) : setKycSelfiePreview(res?.user?.kyc_photo_self);
            setGetProofType(res?.user?.kycproofType)
        }
        setLoading(false);
    }


    const validateImageSize = (imageSize) => {
        const size = (imageSize / 1024 / 1024).toFixed(2);
        if (size >= 15) {
            toast.error("KYC Image must be less than 2 MB!");
            return false;
        }
        return true;
    }


    const handleChange = (event, type) => {
        const file = event.target.files[0];
        if (!format.includes(file.type)) {
            toast.error(`JPG, PNG, JPEG format only allowed!`);
        } else {
            if (type === "front") {
                let isValid = validateImageSize(file.size);
                if (isValid) {
                    setKycFront(file);
                    let reader = new FileReader();
                    reader.onload = () => setKycFrontPreview(reader.result);
                    reader.readAsDataURL(file);
                }
            } else if (type === "back") {
                let isValid = validateImageSize(file.size);
                if (isValid) {
                    setKycBck(file);
                    let reader = new FileReader();
                    reader.onload = () => setKycBackPreview(reader.result);
                    reader.readAsDataURL(file);
                }
            } else {
                let isValid = validateImageSize(file.size);
                if (isValid) {
                    setKycSelfie(file);
                    let reader = new FileReader();
                    reader.onload = () => setKycSelfiePreview(reader.result);
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const updateKyc = async (e) => {
        e.preventDefault();

        if (!proofType) {
            toast.error("Please select a proof type!");
            return;
        }

        if (kycFront === "" || kycBack === "" || kycSelfie === "") {
            toast.error("All images (front, back, and selfie) are required!");
            return;
        }

        let data = new FormData();
        data.append("kycfrontimage", kycFront);
        data.append("kycbackimage", kycBack);
        data.append("kycselfieimage", kycSelfie);
        data.append("proofType", proofType);

        setLoading(true);
        let res = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/DsZQuCOTXHtMM83dixv1`, data, "formData");
        setTimeout(() => {
            if (res?.status) {
                setKycFront("");
                setKycBck("");
                setKycSelfie("");
                setUpdateRes(res);
            }
            setLoading(false);
        }, 1000);
    }
    const isDisabled =
        getKycInfo?.kyc_photo_proof1_status === 1 &&
            getKycInfo?.kyc_photo_proof2_status === 1 &&
            getKycInfo?.kyc_photo_self_status === 1
            ? true
            : getKycInfo?.kyc_photo_proof1_status === 0 ||
                getKycInfo?.kyc_photo_proof2_status === 0 ||
                getKycInfo?.kyc_photo_self_status === 0
                ? true
                : false;

    return (
        <Fragment>
            <div className={loading ? "spinner" : "d-none"}>
                <ScaleLoader color={color} size={50} />
            </div>

            <Header />
            <div className="pgCntr">
                <div className="KYCPg">
                    <div className="container">
                        <h3>KYC Verification</h3>
                        <div className="KYCDv">
                            <div className="KYCDvDts">
                                <h4>KYC</h4>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="proof">Select Proof Type</label>
                                            <select
                                                className="form-control"
                                                value={proofType || getProofType}
                                                onChange={(e) => setProofType(e.target.value)}
                                                disabled={!!getProofType}                                            >
                                                <option value="">Select Proof Type</option>
                                                <option value="GovermentBadge">Government Badge</option>
                                                <option value="Aadhaar">Aadhaar</option>
                                                <option value="Driving License">Driving License</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="status">Overall Status:
                                                {
                                                    getKycInfo?.kyc_photo_proof1_status === 1 && getKycInfo?.kyc_photo_proof2_status === 1 && getKycInfo?.kyc_photo_self_status === 1 ?
                                                        <p className="text-success">Verified</p>
                                                        :
                                                        getKycInfo?.kyc_photo_proof1_status === 2 || getKycInfo?.kyc_photo_proof2_status === 2 || getKycInfo?.kyc_photo_self_status === 2 ?
                                                            <p className="text-danger">Pending</p>
                                                            :
                                                            getKycInfo?.kyc_photo_proof1_status === 0 || getKycInfo?.kyc_photo_proof2_status === 0 || getKycInfo?.kyc_photo_self_status === 0 ?
                                                                <p className="text-danger">Pending</p>
                                                                :
                                                                <p className="text-danger">Choose File</p>
                                                }
                                            </label>
                                            <span>* Format JPEG (.jpg or .jpeg) or PNG format, Sizes less than 5MB</span>
                                            <div className="UpldCdDts row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="UpldCdDtsCt h-100 mb-0">
                                                        <img src={kycFrontPreview ? kycFrontPreview : kycFront ? kycFront : uploadImg}
                                                            className="d-block mx-auto" alt=""
                                                        />
                                                        <p>Upload <br />Front Side ID Proof</p>
                                                        <a href="#">
                                                            {
                                                                getKycInfo?.kyc_photo_proof1_status === 1 ?
                                                                    <p style={{ color: 'greenyellow' }}>Approved</p>
                                                                    :
                                                                    getKycInfo?.kyc_photo_proof1_status === 2 ?
                                                                        <p className="text-danger">Rejected ({getKycInfo?.kyc_photo_proof1_reason})</p>
                                                                        :
                                                                        getKycInfo?.kyc_photo_proof1_status === 0 ?
                                                                            <p className="text-danger">Pending</p>
                                                                            :
                                                                            kycFrontPreview || kycFront ? null :
                                                                                <p className="text-danger">Choose File</p>
                                                            }
                                                        </a>
                                                        <input type="file" className="form-control"
                                                            style={{ cursor: getKycInfo?.kyc_photo_proof1_status === 1 || getKycInfo?.kyc_photo_proof1_status === 0 ? "not-allowed" : "pointer" }} disabled={getKycInfo?.kyc_photo_proof1_status === 1 || getKycInfo?.kyc_photo_proof1_status === 0 ? true : false}
                                                            name="kycfrontimage"
                                                            onChange={(e) => handleChange(e, "front")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="UpldCdDtsCt h-100 mb-0">
                                                        <img src={kycBackPreview ? kycBackPreview : kycBack ? kycBack : uploadImg}
                                                            className="d-block mx-auto"  alt=""
                                                        />
                                                        <p>Upload <br />Back Side ID Proof</p>
                                                        <a href="#">
                                                            {
                                                                getKycInfo?.kyc_photo_proof2_status === 1 ?
                                                                    <p style={{ color: 'greenyellow' }}>Approved</p>
                                                                    :
                                                                    getKycInfo?.kyc_photo_proof2_status === 2 ?
                                                                        <p className="text-danger">Rejected ({getKycInfo?.kyc_photo_proof2_reason})</p>
                                                                        :
                                                                        getKycInfo?.kyc_photo_proof2_status === 0 ?
                                                                            <p className="text-danger">Pending</p>
                                                                            :
                                                                            kycBack || kycBackPreview ? null : <p className="text-danger">Choose File</p>
                                                            }
                                                        </a>
                                                        <input type="file"
                                                            className="form-control"
                                                            style={{ cursor: getKycInfo?.kyc_photo_proof2_status === 1 || getKycInfo?.kyc_photo_proof2_status === 0 ? "not-allowed" : "pointer" }} disabled={getKycInfo?.kyc_photo_proof2_status === 1 || getKycInfo?.kyc_photo_proof2_status === 0 ? true : false}
                                                            name="kycbackimage"
                                                            onChange={(e) => handleChange(e, "back")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="UpldCdDtsCt h-100 mb-0">
                                                        <img src={kycSelfiePreview ? kycSelfiePreview : kycSelfie ? kycSelfie : uploadImg} className="d-block mx-auto"  alt="" />
                                                        <p>Upload <br />KYC Selfie ID Proof</p>
                                                        <a href="#">
                                                            {
                                                                getKycInfo?.kyc_photo_self_status === 1 ?
                                                                    <p style={{ color: 'greenyellow' }}>Approved</p>
                                                                    :
                                                                    getKycInfo?.kyc_photo_self_status === 2 ?
                                                                        <p className="text-danger">Rejected ({getKycInfo?.kyc_photo_self_reason})</p>
                                                                        :
                                                                        getKycInfo?.kyc_photo_self_status === 0 ?
                                                                            <p className="text-danger">Pending</p>
                                                                            :
                                                                            kycSelfiePreview || kycSelfie ? null :
                                                                                <p className="text-danger">Not Upload</p>
                                                            }
                                                        </a>
                                                        <input type="file"
                                                            className="form-control"
                                                            style={{ cursor: getKycInfo?.kyc_photo_self_status === 1 || getKycInfo?.kyc_photo_self_status === 0 ? "not-allowed" : "pointer" }}
                                                            disabled={getKycInfo?.kyc_photo_self_status === 1 || getKycInfo?.kyc_photo_self_status === 0 ? true : false}
                                                            name="kycselfieimage"
                                                            onChange={(e) => handleChange(e, "selfie")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 pt-4">
                                                    {isDisabled ? null : (
                                                        <button className="btn btn-gr btn-15138 mx-1" type="submit" onClick={(e) => updateKyc(e)}>Submit</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default KYC;
