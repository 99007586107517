import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router,useLocation, useNavigate } from "react-router-dom";
import { } from 'reactstrap';
import logoImg from '../../assets/images/logo.png';
import facebookLogo from '../../assets/images/facebook.png';
import twitterLogo from '../../assets/images/twitter.png';
import discordLogo from '../../assets/images/brand-discord.png';
import { useCustomizeContext } from "../Context";


const Footer = () => {
    React.useEffect(() => { window.scrollTo(0, 0); }, []);
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => setScroll(window.scroll > 0);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLinkClick = () => {
        scrollToTop();
        navigate('/'); // Route to home
    };


    const pageTo = (section) => {
        navigate('/');

        setTimeout(() => {
            const element = document.querySelector(section);
            if (element) {
                window.scrollBy({
                    top: element.getBoundingClientRect().top - 25,
                    behavior: 'smooth',
                });
            }
        }, 100); // Adjust the timeout as needed
    };

    const pageLocation = useLocation();

    const [pageType, setPageType] = useState("home");
    const {

        siteInformation: {
            twitter,
            copyrightstext,
            facebook,
            discord,
            footerContent
        },
    } = useCustomizeContext();
    useEffect(() => {
        if (pageLocation.pathname === "/") {
            setPageType("home")
        }
        else {
            setPageType("inner")
        }
    }, [pageLocation])
    const navigate = useNavigate();

    const handlePrivacy = () => {
        navigate('/privacy');
    };
    const handleAboutUs = () => {
        navigate('/aboutus');
    };
    const handleTerms = () => {
        navigate('/terms');
    };


    React.useEffect(() => { window.scrollTo(0, 0); }, []);
    return (
        <Fragment>
            <div className="FtrSec">
                <div className="container">
                    <div className="FtrSecCnt">
                        <div className="FtrSecBg">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="FtrSecCntInr">
                                        <img src={logoImg} alt=""/>
                                        <p>{footerContent}</p>
                                        <h6>{copyrightstext}</h6>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6">
                                    <div className="FtrSecCntInr">
                                        <h4>Menu Links</h4>
                                        <a href="#" to="/" onClick={handleLinkClick}>Home</a>
                                        <a href="#" onClick={handleAboutUs}>About Us</a>
                                        <a href="#faq" onClick={(e) => {
                                            e.preventDefault();
                                            pageTo("#faq");
                                        }}>FAQ</a>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6">
                                    <div className="FtrSecCntInr">
                                        <h4>Other Links</h4>
                                        <a href="#overview"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pageTo("#overview");
                                            }}
                                        >Overview</a>
                                        <a href="#roadmap"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pageTo("#roadmap");
                                            }}
                                        >Roadmap</a>
                                        <a href="#" onClick={handlePrivacy}>Privacy Policy</a>
                                        <a href="#" onClick={handleTerms}>Terms of Service</a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="FtrSecCntInr">
                                        <h4>Follow Us</h4>
                                        <div className="SocFlx">
                                            <a href={twitter} target="_blank" id="telegramLink" >
                                                <img src={twitterLogo} alt="fb.png" />
                                            </a>
                                            <a href={facebook} target="_blank" id="discordLink">
                                                <img src={facebookLogo} alt="insta.png" />
                                            </a>
                                            <a href={discord} target="_blank" id="discordLink">
                                                <img src={discordLogo} alt="insta.png" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default Footer;