import React from "react";
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';


const FAQ = ({homepagedata}) => {

    return (
        <>
            <div className="FAQSec">
                <div className="container">
                    <h3>Frequently Asked Questions.</h3>
                    <Accordion>
                        {homepagedata && homepagedata.faq && homepagedata.faq.map((faqItem, index) => (
                            <AccordionItem key={index} header={faqItem.question}>
                                {faqItem.answer}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </div>
        </>
    );

}
FAQ.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default FAQ;