import React from "react";
import PropTypes from 'prop-types';
import whitebg from '../../../assets/images/whitebg.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useCustomizeContext } from "../../../common/Context";


const WhitePaper = ({ homepagedata }) => {

    const {

        siteInformation: {
            whitePaperLink
        },
    } = useCustomizeContext();



    return (
        <>
            <div className="wtpapersec cmntxt" id="Whitepaper">
                <div className="container">
                    <div className="CmmnHdd text-center mb-4">
                        <h6 className="mb-0">WHITE PAPER </h6>
                        <h4 className="">Get more info about BLOCKWAVE*</h4>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <div className="whitbg">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-lg-6 mb-3 mb-lg-0">
                                        <div>
                                            <h5><span>{homepagedata && homepagedata.tittlewhitepaper}</span></h5>
                                            <p className="my-3">{homepagedata && homepagedata.whitepapercontent}	</p>
                                            <a href={whitePaperLink} target="_blank" className="commonbtn btn">
                                                <span>Download Whitepaper</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-3 mb-lg-0">
                                        <div className="text-center">
                                            <div className="bgimg">
                                                <img src={whitebg} alt="" className="img-fluid bgimg" />
                                                <div className="arimg arrow">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                        <path className=""
                                                            d="M6.66667 0.5C6.66667 0.223857 6.89052 0 7.16667 0H12.9752C13.2513 0 13.4752 0.223858 13.4752 0.5V10.3671H18.8378C19.2767 10.3671 19.5026 10.8922 19.2008 11.2109L10.2947 20.614C10.0965 20.8232 9.76292 20.8221 9.56622 20.6114L0.785566 11.2083C0.487141 10.8888 0.713757 10.3671 1.15101 10.3671H6.66667V0.5Z"
                                                            fill="black" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
WhitePaper.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default WhitePaper;