import "../Pagenotfound/scss/pagenotfound.scss";

import React, { Fragment } from "react";

import pagenotfoundImg from "../../assets/images/ip-address.jpg";
import { useNavigate } from "react-router-dom";

const IpBlock = () => {
  const navigate = useNavigate()

  const Back = () => {
    navigate('/')
  }
  return (
    <Fragment>
      <div className="cntld">
        <div className="btpg">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-12">
                <img src={pagenotfoundImg} className="img-fluid d-block mx-auto" alt="" />
                <button className="btn btn-gr pointer mt-4" onClick={Back}>Back to Home</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

}

export default IpBlock;