import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import bnrspr from '../../../../assets/images/banner-spr.png'
import ellipse from '../../../../assets/images/ellipse.png'
import ellipseSml from '../../../../assets/images/ellipse-small.png'
import sqrBig from '../../../../assets/images/square-big.png'
import sqrSml from '../../../../assets/images/square-small.png'

import { useNavigate } from "react-router-dom";
const Banner = ({ homepagedata }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/buytoken'); 
    };

    return (
        <Fragment>
            <div className="BnrSec">
                <div className="container">
                    <h1 dangerouslySetInnerHTML={{ __html: homepagedata?.tittleone	 }}/>
                    <p
                        dangerouslySetInnerHTML={{ __html: homepagedata?.tittleonedescription }}
                    />
                    <button className={'commonbtn'} onClick={handleClick}>{'Buy Now'}</button>
                    <div className="PosIg">
                        <img src={bnrspr} className="BnrSpr" alt=""/>
                        <img src={ellipse} className="Elspe1" alt=""/>
                        <img src={ellipseSml} className="Elspe2" alt=""/>
                        <img src={ellipseSml} className="Elspe3" alt=""/>
                        <img src={ellipseSml} className="Elspe4" alt=""/>
                        <img src={sqrBig} className="SqrBig" alt=""/>
                        <img src={sqrSml} className="SqrMdm" alt=""/>
                        <img src={sqrSml} className="SqrSml" alt=""/>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}
Banner.propTypes = {
    homepagedata: PropTypes.object.isRequired,
};

export default Banner;