import React, { createContext, useContext, useState } from "react";
import PropTypes from 'prop-types';

const customizeContext = createContext();

export const useCustomizeContext = () => useContext(customizeContext);

export const CustomizeProvider = ({ children }) => {
    const [copyrightstext, setCopyrightstext] = useState("");
    const [twitter, setTwitter] = useState("");
    const [telegram, setTelegram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [facebook, setFacebook] = useState("");
    const [footerContent, setFooterContent] = useState("");
    const [discord, setDiscord] = useState("");
    const [whitePaperLink, setWhitePaperLink] = useState("");
    const [userId, setUserId] = useState('');
    const [userAuthToken, setUserAuthToken] = useState("");

    const siteInformation = {
        telegram, setTelegram,
        twitter, setTwitter,
        youtube, setYoutube,
        copyrightstext, setCopyrightstext,
        whitePaperLink, setWhitePaperLink,
        facebook, setFacebook,
        footerContent, setFooterContent,
        discord, setDiscord,
    };

    const userInfo = {
        userId, setUserId,
        userAuthToken, setUserAuthToken,
    };

    return (
        <customizeContext.Provider value={{ siteInformation, userInfo }}>
            {children}
        </customizeContext.Provider>
    );
};

// Add PropTypes validation
CustomizeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CustomizeProvider;
