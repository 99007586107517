import "../Buytoken/scss/buytoken.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useState } from "react";
import OtpInput from 'react-otp-input';

import { CONST } from "../../config";

import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ClipLoader from "react-spinners/ClipLoader";
import Helper from "../../common/Helper";
import { useCustomizeContext } from "../../common/Context";
import { cryptoModule } from "../../common/Services";

const OTP = (props) => {
  toast.configure();
  const Navigate = useNavigate()
  const [otp, setOtp] = useState('');
  const [saveButton, setButton] = useState(false);

  const {
    userInfo: {
      setUserAuthToken
    }
  } = useCustomizeContext();


  const handleChange = (otp) => {
    setOtp(otp)
  }

  const sendOtp = async (e) => {
    setButton(true)
    e.preventDefault();
    let randomNumber = Math.floor(Math.random() * 100) + 1

    if (otp == " " || otp == "undefined" || (!otp)) {
      setButton(false)
      toast.error('Please Enter Otp');
    }
    else {
      let data = {
        'email': cryptoModule.decrypt(sessionStorage.getItem('email')),
        'password': cryptoModule.decrypt(sessionStorage.getItem('password')),
        'otp': otp ?? otp,
        'login_no': randomNumber
      }

      let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/dii2115iioFTUQqxjuph`, data);
      if (getdata?.status === true && getdata?.resendotp == true) {
        setButton(false)
        toast.success(getdata?.msg)
        setOtp("")
      }
      else if (getdata?.status === true) {
        props.setIsLogin(getdata?.token);
        localStorage.setItem('loginId', getdata?.token);
        localStorage.setItem('login_no', randomNumber);
        setUserAuthToken(getdata?.token);
        setButton(false)
        toast.success('Login Successful')
        Navigate('/');
      }
      else {
        setButton(false)
        toast.error(getdata?.msg)
      }
    }

  }

  const AvoidSpace = (event) => {
    var k = event ? event.which : window.event.keyCode;
    if (k == 32) event.preventDefault()
  }


  return (

    <Fragment>
      <Header />
      <div className="cntld">
        <div className="w-100 LoginRegContainer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-xl-4">
                <form className="loginRegForm">
                  <div className="heading text-center">
                    <h3 className="mb-2">Enter OTP</h3>
                    <p>An 6 digit code has been sent to <br />{cryptoModule.decrypt(sessionStorage.getItem('email'))}</p>
                  </div>
                  <div className="my-3 d-flex justify-content-center OtpSec">
                    <OtpInput
                      className="form-control mx-2 otp"
                      value={otp}
                      onChange={handleChange}
                      onKeyDown={(event) => AvoidSpace(event)}
                      numInputs={6}
                      separator={<span> </span>}
                    />
                  </div>
                  <div className="my-5">
                    <button className="btn btn-block orangeBtn w-100" onClick={sendOtp} disabled={saveButton}>{saveButton ? <ClipLoader color={'#ffffff'} size={25} /> : <span>Submit</span>}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );

}

export default OTP;