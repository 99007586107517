import "../Buytoken/scss/buytoken.scss";
import Header from "../../common/Header/Header"
import Footer from "../../common/Footer/Footer"
import React, { Fragment, useState, useEffect } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody } from "reactstrap";
import downarrowImg from "../../assets/images/downarrow.svg";
import copyImg from "../../assets/images/copy.png";
import leftarrowImg from "../../assets/images/leftarrow.png";
import { CONST } from '../../config/index';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import copy from "../../assets/images/copy.svg";
import ScaleLoader from "react-spinners/ScaleLoader";

import Helper from "../../common/Helper";
import { useCustomizeContext } from "../../common/Context";
import { BLOCK_EXPLORER } from "../../common/Explore_URLS";

const Deposit = (props) => {
  const navigate = useNavigate()
  const [currencyDetails, setCurrencyDetails] = useState('');
  const [currency, setCurrency] = useState('Select Currency');
  const [curImg, setCurImg] = useState('')
  const [curImgOne, setCurImgOne] = useState('')
  const [netType, setNetType] = useState('Select Network');
  const [netTypeOne, setNetTypeOne] = useState('');
  const [coinAddress, setCoinAddress] = useState('');
  const [size, setSize] = useState(400);
  const [bgColor, setBgColor] = useState("ffffff");
  let [color, setColor] = useState("#ffffff");
  const [qrCode, setQrCode] = useState('');
  const [coinType, setCoinType] = useState('')
  const [coinNet, setCoinNet] = useState('Select Network');
  const [isCur, setIsCur] = useState('');
  const [checkHit, setCheckHit] = useState(false);
  const [modalOne, setModalOne] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [currencyContent, setCurrencyContent] = useState('');

  const toggle1 = () => setModalOne(!modalOne);


  const {
    userInfo: {
      userId
    }
  } = useCustomizeContext();


  let count = 0;
  useEffect(() => {
    fetchData();
    fetchNetwork();
    fetchNetworkOne();
    fetchCurrency();
  }, []);

  useEffect(() => {

    if (!checkHit) {
      setCheckHit(true)
      fetchCurrencyDataOne()
      fetchCurrencyData()
    }


  }, [props?.isCurrency])




  var isCurImg;
  const [history, setHistory] = useState('')

  const pageSize = [
    {
      length: '10',
      value: 10
    },
    {
      length: '25',
      value: 25
    },
    {
      length: '45',
      value: 45
    },
    {
      length: '100',
      value: 100
    },
    {
      length: 'All',
      value: ''
    }
  ]


  useEffect(() => {
    if (userId) {
      deposit(pageSize[0]?.value);
    }
  }, [userId]);


  const deposit = async (length) => {
    const depositeData = {
      length: length,
      page: 1,
      userId: userId,
    }
    setLoading(true);
    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/uVl7mos9omPEXkBdegkH`, depositeData);
    if (getdata?.status == true) {
      setHistory(getdata?.data)
    }
    setLoading(false);
  }


  const fetchData = async () => {
    let getdata = await Helper?.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/oHDmRVfD8vqlOZzXq8fN`);
    if (getdata?.status === true) {
      setCurrencyDetails(getdata?.data?.filter((temp) => temp?.symbol !== "BLOCKWAVE"));
      getdata?.data?.map(data => {
        if (props?.isCurrency === data?.name) {
          isCurImg = data?.image
          setIsCur(data?.image)

        }
      })

    }
  }
  const [currencyDetailsOne, setCurrencyDetailsOne] = useState('');
  var iscurImgOne;
  const [isCurOne, setIsCurOne] = useState('');

  const fetchCurrency = async () => {
    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/ogD9GHWOPd7n9cSEjlY7`);
    if (getdata?.status == true) {
      setCurrencyDetailsOne(getdata?.data?.filter((temp) => temp?.symbol !== "BLOCKWAVE"));
      getdata?.data.map(data => {
        if (props?.isCurrency === data?.name) {
          iscurImgOne = data.image
          setIsCurOne(data.image)

        }
      })

    }
  }

  const fetchCurrencyDataOne = async () => {

    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/ogD9GHWOPd7n9cSEjlY7`);
    if (getdata?.status == true) {
      let dataNew = getdata?.data;
      if (dataNew && dataNew.length > 0) {
        dataNew.map(data => {
          if (props?.isCurrency === data?.name) {
            getCurrency(data)
          }
        })
      }

    }
  }

  const fetchCurrencyData = async () => {
    let getdata = await Helper?.GetData(`${CONST?.BACKEND_URL}/sDZuOFOBRwYg/oHDmRVfD8vqlOZzXq8fN`);
    if (getdata?.status == true) {
      let dataNew = getdata?.data;
      if (dataNew && dataNew?.length > 0) {
        dataNew.map(data => {
          if (props.isCurrency === data?.name) {

            getCurrencyOne(data)
          }
        })
      }

    }
  }
  const [currencyone, setCurrencyOne] = useState('Select Currency');
  const [netTypeTwo, setNetTypeTwo] = useState('');
  const [netTypeThree, setNetTypeThree] = useState('Select Network');

  const getCurrencyOne = async (data) => {
    setCurrencyOne(data.name)
    setCurImgOne(data.image)
    let dataone = {
      'name': data.name,
      'networktype': '',
    }
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/ogD9GHWOPd7n9cSEjlY7`, dataone);
    if (getdata?.status == true) {
      setNetTypeTwo(getdata?.data?.filter((temp) => temp?.type === 'token'));
      let temp = getdata?.data;

      setNetTypeThree(temp[0].networktype)
    }

  }

  const fetchNetworkOne = async () => {
    let data = {
      status: 1
    }
    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/KOcucDhyLu7MjDJb8MfM`, data);
    if (getdata?.status === true) {
      let temp = getdata?.data;
      let result = [];
      if (temp && temp.length > 0) {
        result = temp.reduce((unique, o) => {
          if (!unique.some(obj => obj.networktype === o.networktype)) {
            unique.push(o);
          }
          return unique;
        }, []);
      }
      setNetTypeTwo(result)
    }
  }

  const fetchNetwork = async () => {
    let data = {
      status: 1
    }
    let getdata = await Helper.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/KOcucDhyLu7MjDJb8MfM`, data);
    if (getdata?.status === true) {
      let temp = getdata?.data;
      let result = [];
      if (temp && temp.length > 0) {
        result = temp.reduce((unique, o) => {
          if (!unique.some(obj => obj.networktype === o.networktype)) {
            unique.push(o);
          }
          return unique;
        }, []);
      }
      setNetTypeOne(result)
    }
  }

  const getCurrency = async (data) => {
    setCoinAddress('')
    setCurrency(data.name)
    setCurImg(data.image)
    setCoinType(data.type)
    setCoinNet(data.networktype);
    setCurrencyContent(data?.deposit_content);
    setLoading(true);

    let dataone = {
      'name': data.name,
      'networktype': '',
    }
    let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/ogD9GHWOPd7n9cSEjlY7`, dataone);
    if (getdata?.status == true) {
      setNetTypeOne(getdata?.data?.filter((temp) => temp?.name === data.name));
      let temp = getdata?.data;
      setNetType(temp[0]?.networktype);
      await getWalletAddressNew(data, true, temp[0]?.networktype)
    }
    setLoading(false);

  }

  const getWalletAddressNew = async (data, statusParam, networkTypeParam) => {
    let dataone;
    if (statusParam === true) {
      dataone = {
        'currency_id': data._id,
        'currency': data.name,
        'networktype': networkTypeParam
      }
    }
    else if (statusParam === false) {
      dataone = {
        'currency_id': localStorage.getItem('loginId'),
        'currency': currency,
        'networktype': data.networktype,
      }
    }

    if ((data.name && data.name !== 'Select Currency') && networkTypeParam !== 'Select Network') {
      let getdata = await Helper?.PostData(`${CONST?.BACKEND_URL}/Axri1CZK2hX7/KuOH6P8Fk8CTnxcC7xdH`, dataone);
      if (getdata?.status === 1) {
        setCoinAddress(getdata?.address)
        setQrCode(`http://api.qrserver.com/v1/create-qr-code/?data=${getdata?.address}&size=${size}x${size}&bgcolor=${bgColor}`);
        if (getdata?.address && getdata?.address !== '') {
          fetchData()
          props.setIsCurrency('')
        }
      }
    }
  }
  const Back = () => {
    navigate('/wallet');
  }

  const copyClipboard = () => {
    if (coinAddress !== '') {
      navigator.clipboard.writeText(coinAddress);
      toast.success('Address Copied')
    }
  }
  const [tranhash, setTransh] = useState('')
  const [message, setMessage] = useState('')

  const missing = async () => {
    setLoading(true);
    const getresult = await Helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/PtTXv0tuprXHZ5kfjJM9`, { 'currency': currencyone, 'networktype': netTypeThree, "txnid": tranhash, "msg": message });
    if (getresult?.status == true) {
      toast.success(getresult?.msg)
      setModalOne(!modalOne)
    }
    else {
      toast.error(getresult?.msg)
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <div className={loading ? "spinner" : "d-none"}>
        <ScaleLoader color={color} size={50} />
      </div>
      <Header />
      <div className="cntld">
        <div className="w-100 walletContainer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="DepositWithdrawCnt">
                  <div className="head d-flex align-items-center">
                    <img
                      src={leftarrowImg}
                      className="me-2 pointer"
                      onClick={Back}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Space') {
                          Back();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                      alt=""
                    />
                    <h3 className="mb-0">Deposit</h3>
                  </div>
                  <div className="row">
                    <div className="col-lg-5">
                      <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">Select Currency<span className="mandatory">*</span></label>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>
                              <div className="d-flex align-items-center">
                                {curImg ?
                                  <img src={curImg} className="me-2 icon-size" alt="" />
                                  :
                                  <></>
                                }
                                {currency}
                                <img src={downarrowImg} className="ms-auto" alt="" />
                              </div>
                            </DropdownToggle>
                            <DropdownMenu >

                              {currencyDetails && currencyDetails.map((data, index) => (
                                <>
                                  {data?.deposit_status == "active" ?
                                    <DropdownItem tag="a" key={index} onClick={() => getCurrency(data)} >
                                      <div className="d-flex align-items-center">
                                        <img src={data.image} height="22" className="me-2" alt="" />
                                        {data.name}
                                      </div>
                                    </DropdownItem>
                                    :
                                    <></>
                                  }
                                </>

                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">Your Deposit Address<span className="mandatory">*</span></label>
                          <div className="input-group mb-3 cpy_btn">
                            <input type="email" className="form-control pl-5" id="exampleInputEmail1" value={coinAddress} aria-describedby="emailHelp" />
                            <span className="cpy_btn  copybtn pointer" id='emailHelp'>
                              <img
                                src={copyImg}
                                onClick={copyClipboard}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === 'Space') {
                                    copyClipboard();
                                  }
                                }}
                                role="button"
                                tabIndex={0}
                                alt=""
                              />
                            </span>
                          </div>

                        </div>
                      </form>
                    </div>
                    {coinAddress && coinAddress !== '' ?
                      <div className="col-lg-5">
                        <p>Scan QR Code</p>
                        <div className="qrCodeCnt">
                          <img src={qrCode} className="img-fluid" alt="" />

                        </div>
                      </div> : <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Modal isOpen={modalOne}
          toggle={toggle1}
          modalTransition={{ timeout: 2000 }}>
          <ModalBody className="dpMdl">
            <div>
              <h5>Missing Deposit</h5>
            </div>
            <div className="mb-2">
              <label htmlFor="exampleInputEmail1" className="form-label" style={{ color: "white" }}>Select Currency<span className="mandatory">*</span></label>
              <UncontrolledDropdown>
                <DropdownToggle caret>
                  <div className="d-flex align-items-center">
                    {curImgOne ?
                      <img src={curImgOne} className="ms-2 icon-size" alt="" />
                      :
                      <></>
                    }
                    {currencyone}
                    <img src={downarrowImg} className="ms-auto" alt="" />
                  </div>
                </DropdownToggle>
                <DropdownMenu >
                  {currencyDetailsOne && currencyDetailsOne.map((data, index) => (
                    <>
                      {data.currecnystatus == "active" ?
                        <DropdownItem tag="a" key={index} onClick={() => getCurrencyOne(data)} >
                          <div className="d-flex align-items-center">
                            <img src={data.image} height="22" className="me-2" alt="" />
                            {data.name}
                          </div>
                        </DropdownItem>
                        :
                        <></>
                      }
                    </>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="mb-2">
              <h6>Transaction Hash</h6>
              <input type="text" className="form-control " placeholder="Transaction hash" name="tranhash" onChange={(e) => setTransh(e.target.value)} />
            </div>
            <div className="mb-2">
              <h6>Message</h6>
              <input type="text" className="form-control " placeholder="Message" name="message" onChange={(e) => setMessage(e.target.value)} />
            </div>
            {currencyone == "" || currencyone == "undefined" || tranhash == "" || tranhash == "undefined" || message == "" || message == "undefined" ?
              <button className="btn btn-block orangeBtn mt-2 ml-5" disabled>Submit</button>
              :
              <button className="btn btn-block orangeBtn mt-2 ml-5" onClick={missing}>Submit</button>
            }
          </ModalBody>
        </Modal>

        <div className="walletHistoryCnt">
          <div className="histpg">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12">
                  <div className="histtab pt-4">
                    <div className='row'>
                      <div className='col-md-8 mr-1'>
                        <h3 className="p-4">Deposit History</h3>
                      </div>
                      <div className='col-md-4 p-4 mr-4'>
                        <select className="form-control" disabled={history.length === 0 ? true : false} onChange={(e) => deposit(e.target.value)}>
                          {
                            pageSize?.map((temp, index) =>
                              <option key={index} value={temp.value}>{temp?.length}</option>
                            )
                          }
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive wrapper1">
                      <table className="table mb-0">
                        <thead className="sticky">
                          <th>S.No</th>
                          <th>Date & Time</th>
                          <th>Amount</th>
                          <th>Transaction ID</th>
                          <th>Status</th>
                        </thead>
                        <tbody>
                          {history && history.length > 0 ?
                            history && history.map((data) =>
                              <tr>
                                <td>{count = count + 1}</td>
                                <td>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(data.date)}</td>
                                <td>{data.amount} <span> {data?.currency_id === 'TRX' ? "USDT" : data?.currency_id}</span></td>
                                {data.currency_id == "TRX" || data?.currency_id == "USDT" ?
                                  <td>{(data.txnid).slice(0, 4) + '...' + (data.txnid).slice(-4)}<a href={`${BLOCK_EXPLORER?.USDT}${data.txnid}`} target="_blank"><img src={copy} className="ms-2" alt="" /></a></td>
                                  :
                                  data.currency_id == "ETH" ?

                                    <td>{(data.txnid).slice(0, 4) + '...' + (data.txnid).slice(-4)}<a href={`${BLOCK_EXPLORER?.ETH}${data.txnid}`} target="_blank"><img src={copy} className="ms-2" alt="" /></a></td>
                                    :
                                    data.currency_id == "BTC" ?

                                      <td>{(data.txnid).slice(0, 4) + '...' + (data.txnid).slice(-4)}<a href={`${BLOCK_EXPLORER?.BTC}${data.txnid}`} target="_blank"><img src={copy} className="ms-2" alt="" /></a></td>
                                      :
                                      <td>{(data.txnid).slice(0, 4) + '...' + (data.txnid).slice(-4)}<a href={`${BLOCK_EXPLORER?.BLOCKWAVE}${data.txnid}`} target="_blank"><img src={copy} className="ms-2" alt="" /></a></td>

                                }
                                <td className="text-success">{data.status}</td>
                              </tr>
                            )
                            :
                            <><tr><td colspan="5"><span className="nodata m-0">No Data Found</span></td></tr></>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment >
  );

}

export default Deposit;