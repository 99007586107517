import React, { Fragment, useState, useEffect, useContext } from "react";
import "./scss/header.scss";
import { Navbar, NavbarBrand, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { CONST } from "../../config";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import profileOne from '../../assets/images/profile.png'
import helper from "../Helper/index";
import { useCustomizeContext } from "../Context";
import { SocketContext } from "../../socket/index";
import Headers from "../Header2/Header";
import PropTypes from 'prop-types';




const Header = (props) => {
	toast.configure()

	const navigate = useNavigate()
	const locationpath = useLocation();
	var pathname = locationpath.pathname;
	const [profile, setProfileData] = useState('');
	const [loading, setLoading] = useState(false)
	const [status, setStatus] = useState(false)
	const toggle = () => setIsOpen(!isOpen);
	const {

		siteInformation: {
			setWhitePaperLink,
		},
		userInfo: {
			setUserId,
			setUserAuthToken

		},

	} = useCustomizeContext();
	const socket = useContext(SocketContext);
	const [modal1, setModal1] = useState(false);
	const toggle1 = () => {
		setModal1(!modal1)

	};
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const toggle3 = () => {
		setDropdownOpen(!dropdownOpen)
	}
	const [colorChange, setColorchange] = useState(false);
	const changeNavbarColor = () => {
		if (window.scrollY >= 80) {
			setColorchange(true);
		}
		else {
			setColorchange(false);
		}
	};
	window.addEventListener('scroll', changeNavbarColor);

	useEffect(() => {
		setTimeout(() => {
			if (localStorage.getItem('loginId')) {
				fetchData()
			}
		}, 500);
		fetchDataOne()
	}, [])

	const [maintain, setmaintain] = useState('');


	const fetchDataOne = async (values) => {
		let getdata = await helper.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/xh7lBgripKGhlAaFotjw`)
		if (getdata?.status === true) {
			setmaintain(getdata?.data?.sitelogo);
			setWhitePaperLink(getdata?.data?.whitePaper);
		}
	}

	useEffect(() => {

		if (localStorage.getItem('loginId') && props.profilePic) {
			let temp = {};
			temp = profile;
			temp.profile_pic = props.profilePic;
			setProfileData(temp)

		}

	}, [props.profilePic])

	const fetchData = async () => {
		setLoading(true);
		let getData = await helper?.PostData(`${CONST?.BACKEND_URL}/Yq7ss7Xwd8Jc/t6gep9ZEy2XFauVM8o9P`);
		if (getData?.status === true) {
			setProfileData(getData?.user);
			setUserId(getData?.user?.id);
			setStatus(true);
		}
		setLoading(false);
	}

	const logout = async () => {

		socket.emit("logout", localStorage.getItem('loginId'));
		socket.on("logout_get", (get_param) => {
			if (get_param == localStorage.getItem('loginId')) {
				localStorage.clear();
				sessionStorage.clear();
				toast.success(`Logout Successfully !`);
				setUserAuthToken("");
			}
		});
	}
	const [isActive, setActive] = useState(false);
	const toggleMenu = () => {
		setActive(!isActive);
	};
	const [isOpen, setIsOpen] = useState(false);
	const [scroll, setScroll] = useState(false);
	const [isPath, setIsPath] = useState(false);

	useEffect(() => {
		let path = window.location.pathname;
		if (path === "/buytoken") {
			setIsPath(true);
		}
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<>
			{!localStorage.getItem('loginId') || localStorage.getItem('loginId') == "undefined" && status === false && !isPath ?
				<Headers />
				:
				<Fragment>
					<div className={scroll ? "indHd hdrFd scroll" : "indHd hdrFd"}>
						<Navbar className={isActive ? "navbar navbar-expand-xl  sbMenu" : "navbar navbar-expand-xl "}
						>
							<div className="container NavBg d-flex align-items-center">
								<NavbarBrand
									className="me-auto"
									style={{ cursor: 'pointer' }}
									onClick={() => navigate("/")}
								>
									<img src={maintain && maintain} width="200px" alt="" />
								</NavbarBrand>
								<div className="hdNavMenu">
									<Collapse isOpen={isOpen} navbar className="CllpsMenu align-items-center">
										<Nav navbar className="ms-auto">


											<NavItem className="ClsFlx">


												{!localStorage.getItem('loginId') || localStorage.getItem('loginId') == "undefined" && status === false ? <>
													<Link to='/register' className="btn btn-gr ms-2 pt-sm-2">Register</Link>
													<Link to='/login' className="btn btn-gr ms-2 ms-md-5 pt-sm-2">Login</Link>
												</> :
													window.location.pathname == '/buytoken' ?
														<>
															<Link to='/wallet' className="btn btn-gr ms-2 pt-2">Wallet</Link>
														</>
														:
														window.location.pathname == '/' ?
															<><Link to='/wallet' className="btn btn-gr ms-2 pt-2">Wallet</Link>
																<Link to='/buytoken' className="btn btn-gr ms-2 pt-2">Buy Now</Link>
															</>
															:
															<>
																<Link to='/wallet' className="btn btn-gr ms-2 pt-2">Wallet</Link>
																<Link to='/buytoken' className="btn btn-gr ms-2 pt-2">Buy Now</Link>
															</>
												}
											</NavItem>

										</Nav>
									</Collapse>
								</div>
								<Nav Navbar>
									<Dropdown isOpen={dropdownOpen} toggle={toggle3} >
										<DropdownToggle caret className="ms-2 pt-2" tag="span">
											<img src={profile.profile_pic == '' ? profileOne : profile.profile_pic} height="30" className="profile-img" alt="" />
										</DropdownToggle>
										<DropdownMenu className="HdrDrpDwn">
											<Link to='/'><DropdownItem >Home</DropdownItem></Link>
											<Link to='/myprofile'><DropdownItem >Profile</DropdownItem></Link>
											<Link to='/security'><DropdownItem >Security</DropdownItem></Link>
											<Link to='/kyc'><DropdownItem >KYC</DropdownItem></Link>
											<Link to='/login' onClick={logout}><DropdownItem>Logout</DropdownItem></Link>
										</DropdownMenu>
									</Dropdown>
								</Nav>
								<div
									className="mobMenu d-xl-none"
									onClick={toggleMenu}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === 'Space') {
											toggleMenu();
										}
									}}
									role="button"
									tabIndex={0}
								>
									<div
										className={isActive ? "smClose active" : "smClose"}
										onClick={toggle}
										onKeyDown={(e) => {
											if (e.key === 'Enter' || e.key === 'Space') {
												toggle();
											}
										}}
										role="button"
										tabIndex={0}
									>
										<svg id="closeicon" viewBox="0 0 800 600">
											<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
											<path d="M300,320 L460,320" id="middle"></path>
											<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
										</svg>
									</div>
								</div>
							</div>
						</Navbar>
					</div>
				</Fragment>}
		</>
	);

}

Header.propTypes = {
    profilePic: PropTypes.string, // Adjust the type as necessary
};

export default Header;