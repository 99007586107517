import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import moment from "moment";


let toastConfigure = {
    hideProgressBar: true,
    autoClose: 2000,
    position: "top-right",
};

const userKey = "loginId";

const Helper = {

    PostData: async function (url, params, type) {
        let axiosConfig;
        
        let APIToken = await Helper?.signToken({ issuer: 'Y9ofnjwTHWcM', exp: (moment().unix() + 5 * 60) * 1000 })

        if (localStorage.getItem(userKey)) {


            if (type === "formData") {
                axiosConfig = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "false",
                        "Authorization": ` Bearer ${localStorage.getItem(userKey)}`,
                        "jdhwkeiwodjzld": APIToken
                    },
                };
            } else {
                axiosConfig = {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "false",
                        "Authorization": ` Bearer ${localStorage.getItem(userKey)}`,
                        "jdhwkeiwodjzld": APIToken
                    },
                };
            }
        } else {
            axiosConfig = {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "true",
                    "jdhwkeiwodjzld": APIToken
                },
            };
        }

        return await Axios.post(url, params, axiosConfig)
            .then((res) => {
                try {
                    if (res?.status === 200) {
                        let output = res.data;
                        if (output.status === 200 || output.status === true) {
                            toast.success(output.message, toastConfigure);
                            return output;
                        } else if (output.status === 401) {
                        }
                        else if (output.message === "No data found") {
                            return output;
                        }
                        else {
                            toast.error(output.message, toastConfigure);
                        }
                        return output;
                    }
                } catch (e) {

                    toast.error("Internal Server Error !", toastConfigure);
                }
            })
            .catch((e) => {
                toast.error("Internal Server Error !", toastConfigure);
            });
    },

    GetData: async function (url, params) {
        let axiosConfig;
        let APIToken = await Helper?.signToken({ issuer: 'Y9ofnjwTHWcM', exp: (moment().unix() + 5 * 60) * 1000 })
        if (localStorage.getItem(userKey)) {
            axiosConfig = {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "true",
                    "Authorization": ` Bearer ${localStorage.getItem(userKey)}`,
                    "jdhwkeiwodjzld": APIToken
                },
            };
        } else {
            axiosConfig = {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "true",
                    "jdhwkeiwodjzld": APIToken
                },
            };
        }

        return Axios.get(url, axiosConfig)
            .then((res) => res.data)
            .catch((e) => {
                console.log("🚀 ~ e:", e)
                
            });
    },

    formatDated: function (date) {
        date = new Date(date);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        var dates = date.getDate();
        var months = date.getMonth() + 1;
        var years = date.getFullYear();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        dates = dates < 10 ? "0" + dates : dates;
        months = months < 10 ? "0" + months : months;
        var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
        return dates + "/" + months + "/" + years + " " + strTime;
    },

    base64url: (source) => { let encodedSource = CryptoJS.enc.Base64.stringify(source); return encodedSource; },
    encodeToken: (payload) => {
        var header = { "alg": "HS256", "typ": "JWT" };
        var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header)); var encodedHeader = Helper?.base64url(stringifiedHeader);
        var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload)); var encodedData = Helper?.base64url(stringifiedData);
        return encodedHeader + "." + encodedData;
    },
    signToken: (payload) => {
        let token = Helper?.encodeToken(payload);
        var signature = CryptoJS.HmacSHA256(token, "Y9ofnjwTHWcM"); signature = Helper?.base64url(signature);
        var signedToken = token + "." + signature; return signedToken;
    },


    Sitekey: process.env.REACT_APP_SITEKEY

};





export default Helper;
