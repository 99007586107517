import React from "react";
import PropTypes from 'prop-types';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import roadMp from '../../../assets/images/road-mp-bg-btm.png';

const Roadmap = ({ homepagedata }) => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: false,
    speed: 300,
    infinite: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  return (
    <div className="roadmap LghtBg cmntxt" id="roadmap">
      <div className="container">
        <div className="CmmnHdd text-center">
          <h6>{homepagedata && homepagedata.tittlesix}</h6>
          <p
            dangerouslySetInnerHTML={{ __html: homepagedata?.tittlesixsubtitle }}
          />
        </div>
        <div className="RdMpBtmBg">
          <img src={roadMp} alt="Roadmap Background" className="img-fluid" />
        </div>
        <div className="mnhgt">
          <div className="RdMpSldr">
            {homepagedata && homepagedata?.roadmap && (
              <Slider {...settings}>
                {homepagedata.roadmap.map((item, index) => (
                  <div className="posrel1" key={index}>
                    <div className="roadbg roadbg1">
                      <div className="d-flex align-items-start justify-content-between" style={{borderBottom: '1px solid rgba(255, 255, 255, 0.2)'}}>
                        <h6>{item.title.split(" ")[0]}</h6>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <h6>{item.title.split(" ")[1]}</h6>
                      </div>
                      <p className="RdMpScrl"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />                      
                    </div>
                    <div className="posrel11">
                      <svg xmlns="http://www.w3.org/2000/svg" width="54" height="253" viewBox="0 0 54 253" fill="none">
                        <g filter="url(#filter0_f_47_370)">
                          <circle className="blink" cx="27" cy="27" r="13" fill="#FD532F" />
                        </g>
                        <path
                          d="M27 24.3333C25.5272 24.3333 24.3333 25.5272 24.3333 27C24.3333 28.4728 25.5272 29.6667 27 29.6667C28.4728 29.6667 29.6667 28.4728 29.6667 27C29.6667 25.5272 28.4728 24.3333 27 24.3333ZM26.5 248L24.1132 253L29.8867 253L27.5 248L26.5 248ZM26.5 27L26.5 248.5L27.5 248.5L27.5 27L26.5 27Z"
                          fill="white"
                        />
                        <defs>
                          <filter id="filter0_f_47_370" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7" result="effect1_foregroundBlur_47_370" />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
Roadmap.propTypes = {
  homepagedata: PropTypes.object.isRequired,
};

export default Roadmap;
